













import Vue from 'vue';

export default Vue.extend({
  name: 'TTChipGroup',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-chip-group',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
});
